import React from 'react';
import {
    Select,
    TextField,
    MenuItem,
    Switch,
    InputLabel,
    Input,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
} from '@material-ui/core';

export const FeatureSelector = ({ dbInfo, features, setFeatures }) => {
    function toggleFeature(feature: string) {
        if (features.includes(feature)) {
            setFeatures(features.filter((el) => el != feature));
        } else {
            setFeatures(features.concat([feature]));
        }
    }

    return (
        <div id="featureSelectors">
            <List className="featureList">
                {dbInfo.features.map((feature) => {
                    const labelId = `checkbox-list-label-${feature.get('featureName')}`;
                    return (
                        <ListItem
                            key={feature.id}
                            role={undefined}
                            dense
                            button
                            onClick={() => {
                                toggleFeature(feature.get('featureName'));
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={features.includes(feature.get('featureName'))}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={feature
                                    .get('featureTitle')
                                    .replace(/([A-Z])/g, ' $1')
                                    .trim()}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};
