import React, { useState } from "react";
import logo from "./logo.svg";
import styled from "styled-components";
import { CommunityUploader } from "./pages/CommunityUploader";
import { login } from "./api";
import { Button, TextField } from "@material-ui/core";
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  async function handleSubmit() {
    let res = await login(username, password);
    console.log(res);
    if (res.success) {
      setLoggedIn(true);
    } else {
      setError(true);
    }
  }
  return (
    <AppStyle>
      <header></header>
      {loggedIn ? (
        <CommunityUploader />
      ) : (
        <div id="login">
          {error && <h3>Login failed</h3>}
          <TextField
            variant="outlined"
            value={username}
            placeholder="Email/Username"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            value={password}
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      )}
    </AppStyle>
  );
}

const AppStyle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  header {
    height: 3rem;
    width: 100vw;
    background: var(--gs-red);
  }
  #login {
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
      margin: 5px;
    }
  }
`;

export default App;
