import Parse, { Attributes } from "parse";
import axios from "axios";
import {
  HostData,
  ResidentData,
  CommunityData,
  GuestData,
  ContractorData,
  DbInfoInterface,
  UploadData,
  SubmissionData,
  OptionData,
  StatusData,
  ParseObj,
} from "./types";
Parse.initialize(
  "iKSHEDKHIQr4rM2tH2NlNQ6Eh5CAdFLgvztTv6Ci",
  "nEIo4jcgBKldVGXLtAOm13CETimYiBqsua0bEQkH"
);
Parse.serverURL = "https://gatesentry-dev.herokuapp.com/parse/";

export async function sendData(data) {
  try {
    let token = Parse?.User?.current()?.getSessionToken();
    let res = await Parse.Cloud.run("setup_community", {
      info: data,
      sessionToken: token,
    });
    console.log(res);
  } catch (err) {
    alert(err);
  }
}

let status = {};
function GetParseQueryFromTable(table: string) {
  return new Parse.Query(Parse.Object.extend(table));
}

function GetAll(table: string): Promise<ParseObj[]> {
  let query = GetParseQueryFromTable(table);
  return query.find();
}
// function toTitleCase(str) {
//   if (str) {
//     const CapitalWhitelist = ["van", "von"];
//     return str.replace(/\w\S*/g, function (txt) {
//       if (CapitalWhitelist.includes(txt)) {
//         return txt;
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   }
//   return str;
// }
function GetWithOptions(
  table: string,
  options: OptionData[]
): Promise<ParseObj[]> {
  let tQuery = GetParseQueryFromTable(table);
  options.forEach((option) => {
    tQuery[option.method](...option.data);
  });
  //Checks if expunged = false is one of the propeties and if so does the more effecient query
  if (options.some((el) => el.data[0] === "expunged" && el.data[1] === false)) {
    let fQuery = GetParseQueryFromTable(table);
    options.forEach((option) => {
      if (option.data[0] == "expunged") {
        fQuery.doesNotExist("expunged");
      } else {
        fQuery[option.method](...option.data);
      }
    });
    const compoundQuery = Parse.Query.or(tQuery, fQuery);
    return compoundQuery.find();
  } else {
    return tQuery.find();
  }
}
export const login = async (uname, pword) => {
  try {
    // const params = { code };
    // const loginCodeRes = await Parse.Cloud.run('use_login_code', params);
    // await Parse.User.become(loginCodeRes);
    const loginRes = await axios({
      url: `https://gatesentry-dev.herokuapp.com/login/admin`,
      method: "post",
      responseType: "text",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: uname,
        password: pword,
      },
    });
    if (loginRes.data.success) {
      const user = await Parse.User.become(loginRes.data.token);
      return { text: "Login Success", success: true };
    }
    if (loginRes.data?.email) {
      return {
        text: "Login Failed",
        success: false,
        email: loginRes.data.email,
      };
    }

    throw new Error(`Login request failed`);
  } catch (error) {
    return { text: "Login failed", error };
  }
};
function blankParseObject(table: string) {
  const newObj = Parse.Object.extend(table);
  return new newObj();
}

function createWithOptions(table: string, options: object): Promise<ParseObj> {
  const newObj = blankParseObject(table);
  for (const [key, value] of Object.entries(options)) {
    newObj.set(key, value);
  }
  return newObj.save();
}

// async function addCommunityToFeatures(community: ParseObj, features: string[]) {
//   let featureRes: ParseObj[] = [];
//   for await (let feature of features) {
//     let featureObject = await GetWithOptions("Features", [
//       { method: "equalTo", data: ["featureName", feature] },
//     ]);
//     let relation = featureObject[0].relation("enabledCommunities");
//     relation.add(community);
//     featureRes.push(await featureObject[0].save());
//   }
//   return featureRes;
// }

// async function createOrFindContractor(
//   name: string,
//   metro: ParseObj
// ): Promise<ParseObj> {
//   let res = await GetWithOptions("Contractors", [
//     { method: "equalTo", data: ["metroId", metro] },
//     { method: "equalTo", data: ["expunged", false] },
//     { method: "equalTo", data: ["company", toTitleCase(name)] },
//   ]);
//   if (res.length == 0) {
//     return await createWithOptions("Contractors", {
//       company: name,
//       metroId: metro,
//       expunged: false,
//     });
//   }
//   return res[0];
// }

// async function processContractors(
//   contractors: ContractorData[],
//   metro: ParseObj
// ): Promise<ParseObj[]> {
//   const queue = new PQueue({ concurrency: 3 });
//   contractors = Array.from(new Set(contractors));
//   return await queue.addAll([
//     ...contractors.map((contractor) => {
//       return async () => {
//         let res = await createOrFindContractor(contractor.companyName, metro);
//         return res;
//       };
//     }),
//   ]);
// }

// export async function fullSetup(data: SubmissionData) {
//   try {
//     // await Parse.User.logOut();
//     // await Parse.User.become("r:20421b76ccb6893f608e9509772a95c2");
//     let status: StatusData = {};

//     let current = await GetWithOptions("Communities", [
//       {
//         method: "equalTo",
//         data: ["Name", data.community.name],
//       },
//     ]);
//     if (current.length > 0) {
//       throw Error("Community already exists");
//     }

//     //verify if metro exists, if not create
//     let metros = await GetWithOptions("MetroArea", [
//       {
//         method: "equalTo",
//         data: ["Name", data.community.metroArea],
//       },
//     ]);
//     let metroArea;
//     if (metros.length == 0) {
//       metroArea = await createWithOptions("MetroArea", {
//         Name: data.community.metroArea,
//       });
//       status.metro = { info: "New metro created", data: metroArea };
//     } else {
//       metroArea = metros[0];
//       status.metro = { info: "Current metro found", data: metroArea };
//     }
//     console.log(status.metro);
//     //create community
//     let community = await createWithOptions("Communities", {
//       Name: data.community.name,
//       metroId: metroArea,
//       TimeZone: data.community.timeZone,
//     });
//     status.community = { info: "Community Created", data: community };
//     console.log(status.community);

//     //add community to features
//     let featureRes = await addCommunityToFeatures(
//       community,
//       data.community.features
//     );
//     status.features = { info: "Features Added", data: featureRes };
//     console.log(status.features);

//     //create admin data
//     let adminData = await createWithOptions("Admin_Data", {
//       testEmailAccount: "michael.rendon@mail.com",
//       communityId: community,
//       emailEnabled: true,
//       testModeEnabled: true,
//       emailFromAddress:
//         data.community.name.replaceAll(" ", "") + "-Security@GateSentry.com",
//       emailFromName: data.community.name + " Security",
//       requiredVersion: "2.3.7",
//     });
//     status.adminData = { info: "adminData Added", data: adminData };
//     console.log(status.adminData);

//     // Check contractors for metro and add any that aren't duplicates, get list of valid contractors
//     let contractors = await processContractors(
//       data.uploadData.contractors,
//       metroArea
//     );
//     status.contractors = { info: "contractors Added", data: contractors };
//     console.log(status.contractors);

//     // add hosts and their sub info. Resident/authorizations/create codes are handled here.
//     status.hosts = [];
//     const queue = new PQueue({ concurrency: 3 });
//     let hostRes = await queue.addAll([
//       ...data.uploadData.hosts.map((host) => {
//         host.dbInfo.pcommunityId = community;
//         return async () => {
//           let res = await addHostsAndRelated(host, contractors, metroArea);
//           if (status.hosts == undefined) {
//             console.log("hosts undefined");
//             return;
//           }
//           status.hosts.push({ info: "host Added", data: res });
//           console.log(`${status.hosts.length} Hosts Added`);
//         };
//       }),
//     ]);
//     return status;
//   } catch (err) {
//     console.error(err);
//     alert(err);
//     return status;
//   }
// }

export async function getDatabaseInfo(): Promise<DbInfoInterface | undefined> {
  try {
    let data = await Promise.all([
      GetWithOptions("MetroArea", [{ method: "exists", data: ["Name"] }]),
      GetAll("Communities"),
      GetAll("Features"),
    ]);
    return { metro: data[0], communities: data[1], features: data[2] };
  } catch (err) {
    console.log(err);
  }
}
// let count = 0;
// async function createCreateCode(host, code: string | false) {
//   count++;
//   if (count > 15) {
//     console.log("createCode failed");
//     return;
//   }
//   let codeAttempt: string;
//   if (code) {
//     codeAttempt = code;
//   } else {
//     codeAttempt = crypto
//       .randomBytes(20)
//       .toString("base64")
//       .replace(/[^A-Z1-9]/g, "")
//       .replace(/[lI10OS5]/g, "")
//       .slice(0, 8);
//   }
//   if (codeAttempt.length < 6) {
//     return await createCreateCode(host, false);
//   }
//   let res = await GetWithOptions("Create_Codes", [
//     { method: "equalTo", data: ["Code", codeAttempt] },
//   ]);
//   if (res.length > 0) {
//     return await createCreateCode(host, false);
//   }
//   count = 0;
//   return await createWithOptions("Create_Codes", {
//     hostId: host,
//     Code: codeAttempt,
//   });
// }
// function processGuestName(name: String): String {
//   let split = name.split(" ");
//   if (split.length == 1 || name.includes(",")) {
//     return name;
//   }
//   return `${split[split.length - 1]}, ${split.slice(0, -1).join(" ")}`;
// }

// async function addHostsAndRelated(
//   data: HostData,
//   contractors: ParseObj[],
//   metro: ParseObj
// ): Promise<{
//   guestsRes: {
//     visitor: ParseObj;
//     authRes: ParseObj;
//   }[];
//   contractorRes: ParseObj[];
//   residentsRes: ParseObj[];
//   host: ParseObj;
//   codeRes: ParseObj;
// }> {
//   const { residents, guests, contractors: contractorAuths } = data;
//   let host = await createWithOptions("Hosts", data.dbInfo);
//   if (
//     residents === undefined ||
//     guests === undefined ||
//     contractorAuths === undefined
//   ) {
//     throw new Error("data is bad");
//   }
//   let residentsRes: ParseObj[] = [];
//   //create residents
//   for await (let resident of residents) {
//     let res = await createWithOptions("Residents", {
//       ...resident,
//       phostId: host,
//       pcommunityId: host.get("pcommunityId"),
//       expunged: false,
//       priority: 8,
//     });
//     residentsRes.push(res);
//   }
//   let guestsRes: { visitor: ParseObj; authRes: ParseObj }[] = [];
//   //create guest visitors then auths
//   for await (let guest of guests) {
//     let visitor = await createWithOptions("Visitors", {
//       name: processGuestName(guest.name),
//       communityId: host.get("pcommunityId"),
//       metroAreaId: metro,
//       expunged: false,
//     });
//     let authRes = await createWithOptions("Authorizations", {
//       pcommunityId: host.get("pcommunityId"),
//       phostId: host,
//       isCompany: false,
//       isPerm: true,
//       pvisitorId: visitor,
//       expunged: false,
//     });
//     guestsRes.push({ visitor, authRes });
//   }
//   //create contractor auths
//   let contractorRes: ParseObj[] = [];
//   for await (let contractorAuth of contractorAuths) {
//     let res = await createWithOptions("Authorizations", {
//       pcommunityId: host.get("pcommunityId"),
//       phostId: host,
//       pcontractorId: contractors.find(
//         (contractor) =>
//           contractor.get("company") == toTitleCase(contractorAuth.companyName)
//       ),
//       isCompany: true,
//       isPerm: true,
//       expunged: false,
//     });
//     contractorRes.push(res);
//   }

//   //create create code
//   let codeRes: ParseObj;
//   if (data.create) {
//     codeRes = await createCreateCode(host, data.create);
//   } else {
//     codeRes = await createCreateCode(host, false);
//   }
//   return {
//     guestsRes,
//     contractorRes,
//     residentsRes,
//     codeRes,
//     host,
//   };
// }
