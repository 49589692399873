import React, { useEffect } from 'react';
import {
    Select,
    TextField,
    MenuItem,
    Switch,
    InputLabel,
    Input,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
} from '@material-ui/core';
import timezones from '../../util/timezones';

export const TimezoneSelectors = ({ timezone, setTimezone, dbInfo, otherTimezoneGroup, setOtherTimezoneGroup }) => {
    useEffect(() => {
        if (dbInfo) {
            setOtherTimezoneGroup(timezones[0].group);
        }
    }, [dbInfo]);
    return (
        <div id="timezoneSelectors">
            <div className="tzItems">
                <Select
                    id="tzSelector"
                    className="muiItem tzSel"
                    label="Active Timezone Selector"
                    variant="outlined"
                    value={timezone}
                    onChange={(e) => {
                        setTimezone(e.target.value as string);
                    }}
                >
                    {[
                        ...Array.from(
                            new Set([...dbInfo.communities.map((el) => el.get('TimeZone')), timezone, 'Other']),
                        ),
                    ].map((tz) => (
                        <MenuItem key={tz} value={tz}>
                            {tz}
                        </MenuItem>
                    ))}
                </Select>
                <InputLabel htmlFor="tzSelector">
                    {timezone === 'Other' ? 'Please Select a Timezone' : 'Selected Timezone'}
                </InputLabel>
            </div>

            {timezone == 'Other' && (
                <>
                    <div className="tzItems">
                        <Select
                            id="otherTzGroup"
                            className="muiItem tzG"
                            label="Timezone Group Selector"
                            value={otherTimezoneGroup}
                            variant="outlined"
                            onChange={(e) => {
                                setOtherTimezoneGroup(e.target.value as string);
                            }}
                        >
                            {timezones.map(({ group }) => (
                                <MenuItem key={group} value={group}>
                                    {group}
                                </MenuItem>
                            ))}
                        </Select>
                        <InputLabel htmlFor="otherTzGroup">Timezone Groups</InputLabel>
                    </div>
                    <div className="tzItems">
                        <Select
                            id="otherTzSelector"
                            className="muiItem"
                            label="Timezone Selector"
                            variant="outlined"
                            value={'Select'}
                            onChange={(e) => {
                                if (e.target.value != 'Select') {
                                    setTimezone(e.target.value as string);
                                }
                            }}
                        >
                            <MenuItem key="base" value="Select">
                                Select
                            </MenuItem>
                            {timezones
                                .filter((el) => el.group == otherTimezoneGroup)[0]
                                .zones.map(({ value, name }) => (
                                    <MenuItem key={value + name} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                        </Select>
                        <InputLabel htmlFor="otherTzSelector">Timezones</InputLabel>
                    </div>
                </>
            )}
        </div>
    );
};
