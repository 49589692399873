import React from "react";
import {
  Select,
  TextField,
  MenuItem,
  Switch,
  InputLabel,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

export const MetroSelector = ({
  isNewMetro,
  metroArea,
  dbInfo,
  setNewMetro,
  setMetroArea,
}) => {
  function metroChangeHandler(e) {
    let value: string = e.target.value as string;
    setMetroArea(value);
  }
  return (
    <div className="option" id="metroSelect">
      <div className="container">
        {isNewMetro ? (
          <TextField
            className="muiItem"
            label="New Metro Name"
            variant="outlined"
            value={metroArea}
            onChange={metroChangeHandler}
          />
        ) : (
          <Select
            id="metroSelector"
            className="muiItem"
            label="Metro Area"
            variant="outlined"
            value={metroArea}
            onChange={metroChangeHandler}
          >
            {dbInfo.metro.map((metro) => (
              <MenuItem key={metro.id} value={metro.get("Name")}>
                {metro.get("Name")}
              </MenuItem>
            ))}
          </Select>
        )}
        <InputLabel htmlFor="metroSelector">Metro Area</InputLabel>
      </div>
      <div className="optContainer">
        <Switch
          id="newMetroSwitch"
          checked={isNewMetro}
          className="optItem"
          onChange={(e) => {
            setNewMetro(e.target.checked);
            if (e.target.checked) {
              setMetroArea("");
            } else {
              setMetroArea("Australia");
            }
          }}
          name="New Metro"
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <InputLabel htmlFor="newMetroSwitch">New Metro?</InputLabel>
      </div>
    </div>
  );
};
